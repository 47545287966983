import { Card } from "common/types/cards";
import { booleanQueryStringValueTransformer, getQueryStringValue } from "common/utils/helpers";
import {
    CountryCode,
    isPossibleNumber,
    isValidPhoneNumber as libIsValidPhoneNumber,
    parsePhoneNumber,
} from "libphonenumber-js";
import { ANDROID_APP_ID, APPLE_APP_ID } from "../env";
import { formatDate } from "../modules/formatters";
import {
    isAndroid,
    isBlacklistedBrowserType,
    isBraveBrowser,
    isFirefoxBrowser,
    isIOS,
    isMobile,
} from "../modules/platform-detection";
import { ALLOW_RECIPROCAL_QUERY_KEY, DATA_FLOW, EXPERIMENT_2_FLAG } from "./constants";
import { ReferralDeeplinkBuilder } from "./referral-deeplink-builder";

export function getCardData() {
    // This script is deferred so it is the last script to be executed. As a result we know it's safe to check for the JSON
    // encoded script containing the card data (this is supplied by the Cloudflare Worker HTMLRewriter)
    const dataJsonElement = document.getElementById("data") as HTMLScriptElement;
    const cardData = JSON.parse(dataJsonElement.text) as Card;
    return cardData;
}

export function isMobilePlatform() {
    return isMobile() || isIOS() || isAndroid();
}

type OS = "iphone" | "android" | "unknown";

export function getOS(): OS {
    if (isIOS()) {
        return "iphone";
    }
    if (isAndroid()) {
        return "android";
    }

    return "unknown";
}

export function hasUnicode(value: string) {
    // eslint-disable-next-line no-control-regex
    return /[^\u0000-\u007f]/.test(value);
}

export function addStoreParams(deepLink: ReferralDeeplinkBuilder) {
    const appleAppId = APPLE_APP_ID ?? "Blink"; // default to prod
    const androidAppid = ANDROID_APP_ID ?? "com.rabbl.blinq.debug";
    deepLink
        .addDeepQueryParam("apn", androidAppid)
        .addDeepQueryParam("isi", "1324102258")
        .addDeepQueryParam("ibi", `com.rabbl.${appleAppId}`)
        .addDeepQueryParam("imv", "1.2.31")
        .addDeepQueryParam("amv", "1.1.19");
}

export function downloadVCard() {
    const frame = document.getElementById("download_vcard_iframe") as null | HTMLIFrameElement;
    if (frame) {
        frame.src = getVCardLocation();
    } else {
        console.warn("download_vcard_iframe element not found");
    }
}

export function getVCardLocation() {
    const cardData = getCardData();
    const deviceType = isIOS() ? "ios" : "android";
    const locationOfExchange = getLocationOfExchange();
    const encodedLocation = encodeURI(locationOfExchange);
    const dateExchangedCard = getDateOfExchange();
    const encodedDate = encodeURI(dateExchangedCard.full);

    const urlPath = `/vcard/${cardData.cardId}?device=${deviceType}&l=${encodedLocation}&d=${encodedDate}`;

    return urlPath;
}

export function getLocationOfExchange() {
    const currentURL = new URL(window.location.href);
    return currentURL.searchParams.get("l") || "";
}

export function getDateOfExchange() {
    const dateExchangedCard = new Date();
    return formatDate(dateExchangedCard);
}

/**
 * Check if the card being viewed has any valid phone numbers or email addresses.
 * @param cardData The card data currently being accessed
 */
export function hasContactDetails(cardData: Card) {
    if (!cardData.phoneNumbers && !cardData.emails) return false;
    else {
        const validPhoneNumber = cardData?.phoneNumbers?.find(({ value }) => !!value);
        const validEmailAddress = cardData?.emails?.find(({ value }) => !!value);

        return !!validPhoneNumber || !!validEmailAddress;
    }
}

export function monitorShareContactDetailsScroll() {
    const modalEl = document.getElementById("share-contact-info-modal");
    const scrollSentinelEl = modalEl?.querySelector(".scroll-sentinel");

    function onIntersect(entries: IntersectionObserverEntry[]) {
        const scrollSentinel = entries[0];

        if (scrollSentinel.isIntersecting) {
            modalEl?.toggleAttribute("data-show-to-top", false);
        } else {
            modalEl?.toggleAttribute("data-show-to-top", true);
        }
    }

    if (modalEl && scrollSentinelEl) {
        const observer = new IntersectionObserver(onIntersect, {
            threshold: [0, 1],
        });

        observer.observe(scrollSentinelEl);
    }
}

/**
 * Monitor the scroll position of the adaptive sharing buttons container and show a box shadow when the user scrolls
 * the card contents past the save contact button.
 * */
export function monitorAdaptiveSaveContactButtonContainer() {
    const saveContactContainer = document.querySelector(".adaptive-sharing-buttons-container");
    if (!saveContactContainer) {
        return;
    }
    const cardNode = document.getElementsByClassName("blinq-card")[0] as HTMLElement;
    const saveContactContainerSentinel = cardNode.querySelector(".save-btn-sentinel") as HTMLElement;

    function onIntersect(entries: IntersectionObserverEntry[]) {
        const scrollSentinel = entries[0];
        if (scrollSentinel.isIntersecting) {
            saveContactContainer?.toggleAttribute("data-show-box-shadow", false);
        } else {
            saveContactContainer?.toggleAttribute("data-show-box-shadow", true);
        }
    }

    const observer = new IntersectionObserver(onIntersect);

    observer.observe(saveContactContainerSentinel);
}

export function resetShareContactForm() {
    document.querySelector<HTMLFormElement>("#recipient-details-form")?.reset();
}

export function resetSaveContactForm() {
    const saveContactModalEl = document.getElementById("save-contact-modal") as HTMLElement;
    const radioGroup = document.querySelector(".radio-group");
    let defaultRadioBtn: HTMLInputElement;

    const defaultTab = saveContactModalEl.getAttribute("data-default-tab") || "email";
    if (defaultTab === "save") {
        defaultRadioBtn = radioGroup?.querySelector("input:first-of-type") as HTMLInputElement;
    } else if (defaultTab === "email") {
        defaultRadioBtn = radioGroup?.querySelector("input:nth-of-type(2)") as HTMLInputElement;
    } else if (defaultTab === "sms") {
        defaultRadioBtn = radioGroup?.querySelector("input:nth-of-type(3)") as HTMLInputElement;
    } else {
        defaultRadioBtn = radioGroup?.querySelector("input:first-of-type") as HTMLInputElement;
    }

    defaultRadioBtn.click();

    const forms = document.querySelectorAll<HTMLFormElement>("form.send-via-form");
    forms.forEach((formEl: HTMLFormElement) => {
        formEl.reset();
        const formSubmitButton = formEl.querySelector<HTMLButtonElement>('button[type="submit"]');
        if (formSubmitButton) {
            formSubmitButton.disabled = true;
        }
    });
}

/**
 * Given the information provided on the save contact modal prefill the details on the
 * share your contact modal
 */
export function autoFillShareDetailsBackForm() {
    const contactInfoPhoneNumberInput = document.querySelector<HTMLInputElement>(
        "#contact-info-phone-number input"
    );
    const contactInfoPhoneNumberLabelText = document.querySelector<HTMLSpanElement>(
        "#contact-info-phone-number .label-text"
    );
    const contactInfoEmailInput = document.querySelector<HTMLInputElement>("#contact-info-email input");
    const contactInfoEmailInputLabelText = document.querySelector<HTMLSpanElement>(
        "#contact-info-email .label-text"
    );

    const smsInputValue = document.querySelector<HTMLInputElement>("#send-via-sms-input")?.value;
    const countryCodeInputValue = document.querySelector<HTMLInputElement>("#select-country-input")?.value;
    const emailInputValue = document.querySelector<HTMLInputElement>("#send-via-email-input")?.value;

    // Prefill the phone number if possible
    if (smsInputValue && countryCodeInputValue && contactInfoPhoneNumberInput) {
        contactInfoPhoneNumberInput.value = parsePhoneNumber(
            smsInputValue as string,
            countryCodeInputValue as CountryCode
        ).formatInternational();

        contactInfoPhoneNumberLabelText?.classList.add("active");
    }

    // Prefill the email if possible
    if (emailInputValue && contactInfoEmailInput) {
        contactInfoEmailInput.value = emailInputValue;
        contactInfoEmailInputLabelText?.classList.add("active");
    }
}

export function loadImagePromise(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
}
export const noop = () => {
    // do nothing
};

export function playHapticFeedback() {
    if (navigator.vibrate !== undefined) {
        const CLICK = () => navigator.vibrate([15]);
        const DBL_CLICK = () => navigator.vibrate([15, 75, 15]);
        const HEAVY_CLICK = () => navigator.vibrate(100);
        return {
            touch: CLICK,
            success: DBL_CLICK,
            failure: () => {
                DBL_CLICK();
                HEAVY_CLICK();
            },
        };
    }

    return {
        touch: noop,
        success: noop,
        failure: noop,
    };
}

export async function shouldWeAllowDownloadButNotShowExplainerOnIOS() {
    return isMobile() && isIOS() && (isFirefoxBrowser() || (await isBraveBrowser()));
}

export async function shouldWeShowIOSExplainerModal() {
    if (EXPERIMENT_2_FLAG) return false;

    const cardData = getCardData();
    const displayTutorial = cardData.displayTutorial ?? true;

    return (
        isMobile() &&
        isIOS() &&
        displayTutorial &&
        !isBlacklistedBrowserType() &&
        !(await shouldWeAllowDownloadButNotShowExplainerOnIOS())
    );
}

export function shouldWeShowAndroidExplainerModal() {
    if (EXPERIMENT_2_FLAG) return false;

    const cardData = getCardData();
    const displayTutorial = cardData.displayTutorial ?? true;

    return isOnAndroidPhoneWhichSupportsVCardDownload() && displayTutorial;
}

export function isOnAndroidPhoneWhichSupportsVCardDownload() {
    return isMobile() && isAndroid() && !isBlacklistedBrowserType();
}

export async function shouldWeHideDownloadContactTab() {
    return (
        !isMobile() ||
        isBlacklistedBrowserType() ||
        shouldWeShowAndroidExplainerModal() ||
        (await shouldWeShowIOSExplainerModal())
    );
}

export async function showRecommendationForFirefoxBraveUserOnIOS() {
    if (await shouldWeAllowDownloadButNotShowExplainerOnIOS()) {
        const recommendationStatementEl = document.getElementById(
            "recommendation-for-brave-and-firefox-on-ios"
        ) as HTMLElement;
        recommendationStatementEl.style.display = "block";
    }
}

export function hideRecommendationForFirefoxBraveUserOnIOS() {
    const recommendationStatementEl = document.getElementById(
        "recommendation-for-brave-and-firefox-on-ios"
    ) as HTMLElement;
    recommendationStatementEl.style.display = "none";
}

export function showPrivacyStatementInSendContactsBackModal() {
    const shareContactPrivacyPolicy = document.getElementById(
        "share-contact-info-privacy-policy"
    ) as HTMLElement;
    shareContactPrivacyPolicy.style.display = "block";
}

export function hidePrivacyStatementInSendContactsBackModal() {
    const shareContactPrivacyPolicy = document.getElementById(
        "share-contact-info-privacy-policy"
    ) as HTMLElement;
    shareContactPrivacyPolicy.style.display = "none";
}

/**
 * We will allow people to scan their contact book and send their card to a
 * friend on the mobile apps. When that friend opens the card we already
 * know the card sharer has their contact details so there is no real point
 * in showing the exchange info form (outside of the potential use case
 * that the person is sharing to get more specific details but we can deal
 * with that later) so we can jump straight from the download contact to
 * asking the person to get Blinq.
 *
 * This function checks if the user got to the card from the above flow. In which case we
 * shouldn't show the exchange info form.
 */
export function shouldShowReciprocalContactDetailsModal() {
    const cardData = getCardData();
    const cardHasReciprocalContactOptionOn = cardData.displaySendContactInfoForm ?? true;
    const cardComesFromEmailSignature = !!cardData.emailSignatureId;
    const allowReciprocalShare = getQueryStringValue(
        window.location.search,
        ALLOW_RECIPROCAL_QUERY_KEY,
        booleanQueryStringValueTransformer(true)
    );

    return cardHasReciprocalContactOptionOn && !cardComesFromEmailSignature && allowReciprocalShare;
}

export function fromHTMLString(html: string): Element | HTMLCollection {
    const template = document.createElement("template");
    template.innerHTML = html;
    const result = template.content.children;

    if (result.length === 1) {
        return result[0];
    }
    return result;
}

/**
 * Check if the modal has the specified flow type by accessing the data attribute on the element provided
 * Removes the data attribute once it's been checked so it doesn't interfere with future checks
 * @param modalId the ID of the element with which to check the data attribute on
 * @param flow the flow type to check for
 * @returns a boolean for if the data attribute matches the flow type
 */
export function checkModalFlowType(modalId: string, flow: DATA_FLOW): boolean {
    const modal = document.getElementById(modalId);
    if (!modal) return false;

    const isFlow = modal.getAttribute("data-flow") === flow;
    modal.removeAttribute("data-flow");

    return isFlow;
}

export function getContrastYIQ(hexcolor: string) {
    const r = parseInt(hexcolor.substring(1, 3), 16);
    const g = parseInt(hexcolor.substring(3, 5), 16);
    const b = parseInt(hexcolor.substring(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
}

/**
 * Get a sanitised form data for a given field/key.
 */
export function getSanitisedFormValue(formData: FormData, key: string): string {
    return String(formData.get(key) ?? "").trim();
}
/**
 * Validates a phone number string against various formats and rules
 *
 * @param phoneNumber - The phone number string to validate
 * @param countryCode - Optional ISO 3166-1 alpha-2 country code (e.g., 'US', 'GB', 'AU')
 *
 * @returns boolean - Returns true if the phone number is valid according to the following rules:
 * - If countryCode is provided: validates using libphonenumber-js strict validation for that country
 * - If no countryCode: first tries libphonenumber-js loose validation
 * - Fallback validation if above fails:
 *   - Must contain between 3-15 digits
 *   - Allows spaces, parentheses, dots, hyphens and plus signs
 *   - Strips all formatting before checking digit count
 *
 * @example
 * // Without country code - loose validation
 * isValidPhoneNumber('212-555-1212') // returns true
 * isValidPhoneNumber('(212) 555 1212') // returns true
 * isValidPhoneNumber('1-800-FLOWERS') // returns false - contains letters
 */
export function isValidPhoneNumber(phoneNumber: string, countryCode?: CountryCode): boolean {
    // Regex for invisible characters. These characters can be added to phone numbers when pasting from certain sources, or using keyboards with right-to-left scripts.
    const invisibleCharsRegex = /[\u200B-\u200F\u202A-\u202E\uFEFF]/g;

    // Sanitize input: remove invisible characters and trim spaces
    const sanitisedPhoneNumber = phoneNumber.replace(invisibleCharsRegex, "").trim();

    // If country code is provided, validate strictly using the sanitized number
    if (countryCode) {
        const isValidCountryCodeNumber = libIsValidPhoneNumber(sanitisedPhoneNumber, countryCode);

        if (!isValidCountryCodeNumber) {
            console.warn(`Phone number validation fails with country code: ${countryCode} ${phoneNumber}`);
        }

        return isValidCountryCodeNumber;
    }

    const isValidPossibleNumber = isPossibleNumber(sanitisedPhoneNumber);

    if (isValidPossibleNumber) {
        return true;
    }

    console.warn(
        `Phone number validation fails with no country code, reverting to fall back validation: ${phoneNumber}`
    );

    // Remove common formatting characters for fallback validation
    const allowedCharacters = /[+\- ().]/g;
    const finalSanitisedPhoneNumber = sanitisedPhoneNumber.replace(allowedCharacters, "");

    // Validate against E.164 format
    const isValidE164Number = /^\d{3,15}$/g.test(finalSanitisedPhoneNumber);

    if (!isValidE164Number) {
        console.warn(`Phone number is not valid E.164 format: ${phoneNumber}`);
    }

    return isValidE164Number;
}
