import { MetadataWhenSendBackDetails } from "common/types/analytics-events";
import { ShareDetailsFormData } from "common/types/share-details";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { Modal } from "../../controllers";
import { toggleErrorState } from "../../controllers/forms";
import { FUNCTIONS_HOST } from "../../env";
import { progressBarAnimation } from "../../modules/animations";
import { blinqApiGraphQLClient, postData } from "../../modules/api";
import { isIOS, isMobile } from "../../modules/platform-detection";
import { SHARE_DETAILS_STORAGE_KEY } from "../../modules/prefill";
import { analytics } from "../analytics";
import { storeInstance } from "../analytics/store";
import { PaperDesignSystemToast } from "../components/paper-design-system-toast";
import {
    getCardData,
    getDateOfExchange,
    getLocationOfExchange,
    getSanitisedFormValue,
    isValidPhoneNumber,
    playHapticFeedback,
} from "../utils";
import { receiveCardViaEmail } from "./save-contact";

export function toAnalyticsProperties(parsedFormData: ShareDetailsFormData): MetadataWhenSendBackDetails {
    return {
        is_sending_back_first_name: !!parsedFormData.first_name,
        is_sending_back_last_name: !!parsedFormData.last_name,
        is_sending_back_email: !!parsedFormData.email,
        is_sending_back_phone_number: !!parsedFormData.phone_number,
        is_sending_back_job_title: !!parsedFormData.job_title,
        is_sending_back_org_name: !!parsedFormData.org_name,
    };
}

export type OnShareDetailsBackSuccessfully = (parsedFormData: ShareDetailsFormData | null) => void;

/** Handle sharing the contact details back with the card's owner */
export async function handleShareDetailsFormSubmit(event: Event, onFinish?: OnShareDetailsBackSuccessfully) {
    event.preventDefault();

    analytics.track({
        name: "send_clicked",
        properties: { page_id: "share_contact_details_modal" },
    });

    const cardData = getCardData();

    const formEl = event.target as HTMLFormElement;
    const formData = new FormData(formEl);
    const locationOfExchange = getLocationOfExchange();
    const dateExchangedCard = new Date();
    const day = dateExchangedCard.getUTCDate();
    const month = dateExchangedCard.getUTCMonth() + 1; //months from 1-12
    const year = dateExchangedCard.getUTCFullYear();

    let missingInformation = false;

    const email = getSanitisedFormValue(formData, "email");
    const phoneNumber = getSanitisedFormValue(formData, "phone_number");

    if (!email && !phoneNumber) {
        const emailInputEl = document.getElementById("contact-info-email");
        const phoneNumberInputEl = document.getElementById("contact-info-phone-number");

        toggleErrorState(emailInputEl, true, "Please provide at least one of these fields");
        toggleErrorState(phoneNumberInputEl, true, "Please provide at least one of these fields");

        missingInformation = true;
    }

    /// For some experiments like the adaptive sharing experiment,
    /// we only require the full name instead of first and last name
    const nameFromForm = getSanitisedFormValue(formData, "name");
    const firstNameFromForm = getSanitisedFormValue(formData, "first_name");
    const lastNameFromForm = getSanitisedFormValue(formData, "last_name");

    const minimumNameDetails = firstNameFromForm || nameFromForm;
    if (minimumNameDetails.length === 0) {
        const firstNameInputEl = document.getElementById("contact-info-first-name");
        const nameInputEl = document.getElementById("contact-info-name");

        firstNameInputEl?.toggleAttribute("data-error", true);
        nameInputEl?.toggleAttribute("data-error", true);

        missingInformation = true;
    }

    if (missingInformation) {
        const errorEl = formEl.querySelector<HTMLDivElement>("[data-error]");
        if (errorEl) {
            const errorInput = errorEl.querySelector<HTMLInputElement>("input");

            errorInput?.focus();
        }
        onFinish?.(null);
        return;
    }

    // validate email
    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        const emailEl = document.getElementById("contact-info-email");
        toggleErrorState(emailEl, true, "Please enter a valid email");
        onFinish?.(null);
        return;
    }

    // validate phone number
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
        console.warn("Invalid phone number", { phoneNumber });
        const phoneNumberEl = document.getElementById("contact-info-phone-number");
        toggleErrorState(phoneNumberEl, true, "Please enter a valid phone number");
        onFinish?.(null);
        return;
    }

    const splitName = nameFromForm.split(" ").filter(Boolean);

    /// As discussed with product, we will take the first part of the name and
    /// the last part of the name as first name and last name respectively
    const firstName = firstNameFromForm || splitName[0] || "";
    const lastNameFromSplitName = splitName.length > 1 ? splitName[splitName.length - 1] : "";
    const lastName = lastNameFromForm || lastNameFromSplitName || "";

    const jobTitle = getSanitisedFormValue(formData, "job_title");
    const orgName = getSanitisedFormValue(formData, "org_name");

    const parsedFormData: ShareDetailsFormData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        job_title: jobTitle,
        org_name: orgName,
    };

    if (isMobile()) {
        localStorage.setItem(
            SHARE_DETAILS_STORAGE_KEY,
            JSON.stringify({ name: nameFromForm, ...parsedFormData })
        );
    }

    Blinq.persistShareDetailsFormData(parsedFormData);

    try {
        // Must wait before finishing the form submission, lazy load the preview to reduce initial bundle size
        const { renderCardPreview } = await import(/* webpackPrefetch: true */ "../components/card-preview");
        await renderCardPreview(
            {
                shareFormData: parsedFormData,
                connectedFirstName: cardData.firstName,
            },
            "get-blinq-card-preview"
        );
    } catch (e) {
        console.error(`Error rendering card preview`, e);
    }

    const data = {
        referrer_card_id: cardData.cardId,
        referrer_org_id: cardData.orgId,
        ...parsedFormData,
        day: day,
        month: month,
        year: year,
        location: locationOfExchange,
        send_email: storeInstance.saveContactMethod === "save",
    };

    try {
        analytics.track({
            name: "details_reciprocal_shared",
            properties: { ...toAnalyticsProperties(data) },
        });

        storeInstance.userHasFilledShareDetailsForm = true;

        blinqApiGraphQLClient.addRecipientDetails({
            referrer_card_id: data.referrer_card_id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            job_title: data.job_title,
            org_name: data.org_name,
            day: data.day,
            month: data.month,
            year: data.year,
            location: data.location,
            send_email: data.send_email,
        });

        if (onFinish) {
            onFinish(parsedFormData);
            return;
        }

        const modalEl = document.getElementById("share-contact-info-modal");
        if (modalEl) {
            const instance = Modal.getInstance(modalEl);
            if (instance) instance.close();
        }
    } catch (e) {
        console.log(e);
    }

    return false;
}

/** Handle sending the card's details via email to the user */
export async function handleSendViaEmailFormSubmit(event: Event) {
    analytics.track({
        name: "received_clicked",
        properties: {
            page_id: "send_details_to_self_modal",
        },
    });

    event.preventDefault();
    storeInstance.saveContactMethod = "email";

    const formData = new FormData(event.target as HTMLFormElement);
    const emailToSendTo = getSanitisedFormValue(formData, "email");

    const { animation } = progressBarAnimation(".send-contact", 1000);
    const doneAnimation = animation({ autoplay: false });

    try {
        // Play the animation
        doneAnimation.play();

        if (!emailToSendTo) {
            const emailInput = document.getElementById("send-via-email-text-field");
            emailInput?.toggleAttribute("data-error", true);
            await doneAnimation.finished;
            return;
        } else {
            receiveCardViaEmail(emailToSendTo);
        }

        await doneAnimation.finished;

        const cardData = getCardData();
        const firstName = cardData.firstName;

        PaperDesignSystemToast({
            primaryText: `${firstName ? `${firstName}’s` : "The"} contact has been sent to your email`,
            duration: 3000,
            toastType: "success",
        }).render();

        // Moved Save to Email Track till after the email has been successfully sent.
        analytics.track({
            name: "contact_saved",
            properties: {
                save_card_method: "email",
                page_id: "send_details_to_self_modal",
            },
        });

        const modalEl = document.getElementById("save-contact-modal");
        if (modalEl) {
            const instance = Modal.getInstance(modalEl);
            if (instance) instance.close();
        }
    } catch (e) {
        // We need to notify the user...
        console.log(e);
        playHapticFeedback().failure();
    } finally {
        // reset the animation
        doneAnimation.seek(0);
        doneAnimation.pause();
    }
}

export async function handleSendViaSMSFormSubmit(event: Event) {
    event.preventDefault();
    const cardData = getCardData();
    storeInstance.saveContactMethod = "sms";

    const url = `${FUNCTIONS_HOST}/sendContactCardToSelfViaSMS`;
    const formData = new FormData(event.target as HTMLFormElement);
    const rawPhoneNumber = getSanitisedFormValue(formData, "send-via-sms-input");
    const countryCode = getSanitisedFormValue(formData, "selected-country-input");
    const turnstileResult = formData.get("cf-turnstile-response");

    if (!turnstileResult) {
        return;
    }

    if (!rawPhoneNumber) {
        const smsInput = document.getElementById("send-via-sms-input");
        smsInput?.toggleAttribute("data-error", true);
    }

    const locationOfExchange = getLocationOfExchange();
    const dateExchangedCard = getDateOfExchange();
    const operatingSystem = isIOS() ? "ios" : "android";
    const data = {
        referrerCardId: cardData.cardId,
        phoneNumber: parsePhoneNumber(
            rawPhoneNumber as string,
            countryCode as CountryCode
        ).formatInternational(),
        location: locationOfExchange,
        date: dateExchangedCard.full,
        os: operatingSystem,
        referralData: storeInstance.getReferralData(),
        turnstileResult: turnstileResult,
    };

    const { animation } = progressBarAnimation(".send-contact", 1000);
    const doneAnimation = animation({ autoplay: false });

    try {
        // Play the animation
        doneAnimation.play();

        postData(url, data);
        analytics.track({
            name: "contact_saved",
            properties: { save_card_method: "sms" },
        });

        await doneAnimation.finished;

        const modalEl = document.getElementById("save-contact-modal");
        if (modalEl) {
            const instance = Modal.getInstance(modalEl);
            if (instance) instance.close();
        }
    } catch (e) {
        // We need to notify the user...
        console.log(e);
        playHapticFeedback().failure();
    } finally {
        // reset the animation
        doneAnimation.seek(0);
        doneAnimation.pause();
    }
}
